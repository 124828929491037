import { isEmpty } from "lodash"
import React, { useEffect } from "react"
import { Controller } from "react-hook-form"

export const HookField = ({
    name,
    info,
    label,
    setError,
    errors,
    control,
    children,
    setValue,
    validation,
    defaultValues,
    className = "",
}) => {
    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            setValue(name, defaultValues[name])
        }
    }, [defaultValues])
    return (
        <div className={`field ${className}`}>
            {label ? <p className="label">{label}</p> : null}
            <Controller
                control={control}
                name={name}
                rules={validation}
                defaultValue={defaultValues[name]}
                render={({ field }) => {
                    const { name, value, onChange, onBlur } = field
                    const errorHooks = !isEmpty(errors) && errors[name]
                    const defaultValue = defaultValues[name]
                    return (
                        <>
                            {
                                typeof children === "function" ?
                                    children({
                                        name, value, onChange, onBlur, setError, errors, errorHooks, defaultValue, setValue
                                    })
                                    : React.cloneElement(children, {
                                        name, value, onChange, onBlur, setError, errors, errorHooks, defaultValue, setValue
                                    })
                            }
                        </>
                    )
                }}
            />
            { }
            {!isEmpty(errors) && errors[name] ?
                <small className="text-danger">{errors[name]?.message}</small> :
                info ? <small>{info}</small> : null
            }
        </div>
    )
}