import Link from "@components/anti/Link";

export const Tabs = ({
  btnAction = () => {},
  dataList,
  className,
  classNameItems,
  active,
  type = "button",
}) => {
  const generateTabsItem = (data) => {
    if (type === "link") {
      const urlSort = `/${data?.url}`;
      const activeTab = active === urlSort;
      return (
        <Link
          key={data.id}
          href={data.url}
          className={`tabs-item ${classNameItems} ${
            activeTab ? "active fadeIn" : ""
          }`}
        >
          {data.title}
        </Link>
      );
    } else {
      const activeTab = active === data.id;
      return (
        <button
          key={data.id}
          onClick={() => btnAction(data.id)}
          className={`tabs-item ${classNameItems} ${
            activeTab ? "active fadeIn" : ""
          }`}
        >
          {data.title}
        </button>
      );
    }
  };

  return (
    <div className={`sc-tabs ${className}`}>
      <div className="container">
        <div className="tabs-wrapper">
          {dataList?.map((item) => generateTabsItem(item))}
        </div>
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  dataList: [
    {
      id: 1,
      title: "Tab Left",
    },
    {
      id: 2,
      title: "Tab Right",
    },
  ],
  active: 1,
};
