/* eslint-disable @next/next/no-img-element */
// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ========================================================================================================================================
// Image
// ========================================================================================================================================

import React from "react";
import PropTypes from "prop-types";
import NextFutureImage from "next/image";

export function Image({
  className,
  id,
  ratio,
  src,
  ratioClassName,
  alt,
  onClick,
  priority = true,
  quality,
  fill,
  sizes,
  width,
  height,
  ...props
}) {
  return (
    <>
      {ratio ? (
        <div
          className={`image ratio ${ratio} ${ratioClassName}`}
          id={id}
          onClick={onClick}
          role="presentation"
        >
          <div className="outer-content">
            <div className="inner-content">
              <NextFutureImage
                width={width}
                src={src}
                alt={alt}
                height={height}
                className={`${className}`}
                priority={priority}
                quality={quality}
                fill={fill}
                sizes={sizes}
                {...props}
              />
            </div>
          </div>
        </div>
      ) : (
        <NextFutureImage
          src={src}
          alt={alt}
          height={height}
          width={width}
          className={`${className}`}
          priority={priority}
          quality={quality}
          fill={fill}
          sizes={sizes}
          {...props}
        />
      )}
    </>
  );
}

Image.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  ratio: PropTypes.string, // e.g. .r-16-9, .r-sm-3-2, .r-md-4-3, .r-lg-5-4, .r-xl-1-1
  src: PropTypes.any,
  alt: PropTypes.string,
  ratioClassName: PropTypes.string,
  onClick: PropTypes.any,
  priority: PropTypes.bool,
  quality: PropTypes.number,
  fill: PropTypes.bool,
  sizes: PropTypes.string,
};

Image.defaultProps = {
  className: "",
  ratioClassName: "",
  alt: "Image",
  priority: false,
  quality: 75,
  fill: false,
};
